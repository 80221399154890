$border-color: #111;

li {
  vertical-align: text-top;
  text-align: -webkit-center;
  list-style-type: none;
  padding: 20px 3px 0 3px;
  display: inline-block;
  &.rootCard {
    padding: 0px;
  }
}
// .card {
//   max-width: 151px;
//   display: inline-block;
//   border-color: $border-color;
// }
// .card-body {
//   padding: 8px;
// }
ul {
  padding: 20px 0px 0px 10px;
  white-space: nowrap;
  &.rootCard {
    padding: 0px;
    margin: 0px;
  }
}
.rootCard * {
  position: relative;
}

.node li {
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid $border-color;
    width: 50%;
    height: 20px;
  }
  &:after {
    right: auto;
    left: 50%;
    border-left: 1px solid $border-color;
  }
  &:only-child {
    padding-top: 0;
    &:after,
    &:before {
      content: "";
      display: none;
    }
  }
  &:first-child::before,
  &:last-child::after {
    border: 0;
  }
  &:last-child::before {
    border-right: 1px solid $border-color;
    border-radius: 0 5px 0 0;
  }
  &:first-child::after {
    border-radius: 5px 0 0 0;
  }
}

.node ul {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid $border-color;
    width: 0;
    height: 20px;
  }
}
